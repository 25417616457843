import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container } from '@mui/joy';
import { TextField } from '@/components/InputField';
import { HBox } from '@/components/HBox';

import i18n from '@/i18n'

import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';
import { useHostname } from '@/contexts';
import { Loading } from '@/components/Loading';


import { useParams } from 'react-router-dom';
import { ArrowLeft, Check } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useResource } from '@/hooks';

import { db, doc, setDoc } from '@/client';

export default function EditPayeePage() {
  const { payeeId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <EditPayeeView payeeId={payeeId} />
    </React.Suspense>
  )
}

export function PayeeForm({
  payee,
  values: defaultValues = {},
  onSubmit,
  onValuesChange = () => console.warn('onValuesChange not implemented')
}) {
  const [values, setValues] = useState(defaultValues)

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(values)
    return false
  }

  useEffect(() => {
    if (payee) {
      setValues({
        ...payee
      })
    }
  }, [payee])

  useEffect(() => {
    onValuesChange(values)
  }, [values])

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Box>
        <TextField
          name="name"
          required
          defaultValue={payee?.name}
          label={i18n.t('payee-name')}
          placeholder={i18n.t("enter-payee-name")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        <TextField
          name="email"
          type="email"
          required
          defaultValue={payee?.email}
          label={i18n.t('payee-email')}
          placeholder={i18n.t("enter-payee-email")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        <SelectRelatedNodes
          label={i18n.t('users')}
          nodeType="user"
          addLabel={"add-user"}
          ownerNodeType="payee"
          ownerNodeId={payee?.id}
          allLabel={i18n.t('all-users')}
          ownLabel={i18n.t('payee-s-users')}
          nodes={payee?.members ?? []}
          onChange={(payee_users) => {
            setValues({ ...values, payee_users })
          }}
        />
        <Button type="submit" startDecorator={<Check />} variant="solid">{i18n.t('save-payee')}</Button>
      </Box>
    </form>
  )
}

export function EditPayeeView({
  payeeId
}) {
  const hostname = useHostname()
  const navigate = useNavigate()

  const {
    data: payee,
    loading
  } = useResource({ queryKey: ['payee'], path: `/payee/${payeeId}`})

  if (loading) {
    return <Loading />
  }

  const handleSubmit = (values) => {
    console.log(values)
    const result = setDoc(doc(db, 'payees', payeeId), {
      ...values
    }).then(() => {
      navigate(`/dashboard/payee/${payeeId}`)
    })
  }

  if (payee) {
    return (
      <Container>
        <HBox component={Link}>
          <ArrowLeft />
          <Typography>{payee?.name}</Typography>
        </HBox>
        <PayeeForm
          payee={payee}
          onSubmit={handleSubmit}
        />
      </Container>
    )
  }
}