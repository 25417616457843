import React from 'react'

import { Typography } from '@mui/joy';

import Handlebars from 'handlebars'
import { createContext } from 'react';
import { useContext } from 'react';
import { FeatureCard } from '../FeatureCard';
import { Link } from 'react-router-dom';
import { Books } from '@phosphor-icons/react';

export const ContentBlockContext = createContext({
    data: null,
    setData: () => {}
})

/**
 * From https://stackoverflow.com/questions/28894116/regex-youtube-url-for-embed-with-or-without-playlist
 */
function embedVideo(url) {
    var youtube = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
        yt = url.match(yt);
  
    if (yt) {
      return "//www.youtube.com/embed/" + yt[1];
    }
    return false;
  }

  
function formatHTML(value, data) {
    let html = value
    const template = Handlebars.compile(value)
    html = template(data)
    html = html?.replace(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/, (url) => {
        return `<iframe frameborder="0" style="width: 100%; aspect-ratio: 16/9"  src="https://youtube.com/embed/${url.split('=')[1]}"></iframe>`
    })
    html = html?.replace(
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi,
        (email) => `<a href="mailto:${email}">${email}</a>`
    )

    return html
}

export function ParagraphBlock({
    content,
}) {
    return (
       <>
        {content?.map(block => <Block {...block} />)}
       </>
    )
}

export function TextBlock({
    nodeType,
    value,
    ...props
}) {
    const { data } = useContext(ContentBlockContext);
    console.log("label", data.label)
    return (
        <p {...props} dangerouslySetInnerHTML={{ __html: formatHTML(value, data) }} />
    )
}

export function LinkBlock({
    nodeType,
    name,
    reference,
    ...props
}) {
    const { data } = useContext(ContentBlockContext);
    console.log("label", data.label)

    return (
        <FeatureCard
            sx={{ marginBottom: 1, p: 1 }}
            as={Link}
            name={reference.name}
            Icon={Books}
            to={`/dashboard/docs/${reference.slug}`}
        />
    )
}

export function PageBlock({
    nodeType,
    name,
    content,
    ...props
}) {
    if (!(content instanceof Array)) {
        return <></>
    }

    return (
        <article {...props}>
            <Typography level="h2" component="h2">{name}</Typography>
            {content?.map(block => <Block {...block} {...block.content} />)}
        </article>
    )
}

export function DocumentBlock({
    nodeType,
    name,
    content,
    ...props
}) {
    return (
        <article {...props}>
            {content?.map(block =><Block {...block} />)}
        </article>
    )
}

export function Block({
    nodeType,
    ...props
}) {
    switch (nodeType) {
        case "document":
            return (
                <DocumentBlock {...props} nodeType={nodeType} />
            )
        case "paragraph":
            return (
                <ParagraphBlock {...props} nodeType={nodeType} />
            )
        case "text":
            return (
                <TextBlock {...props} nodeType={nodeType} />
            )
        case "blockLink":
        case "link":
            return (
                <LinkBlock {...props} nodeType={nodeType} />
            )
    }
}
