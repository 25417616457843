import React, { useEffect } from 'react'

import { Outlet, useNavigate } from 'react-router-dom'

import { getCurrentUser } from '@/actions/login'

export default function LoginLayout() {
    const navigate = useNavigate()
    return <Outlet />
}
