import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom'; 

import { createMagicLink, getCurrentUser, loginWithUsernameAndPassword } from '@/actions/login';

import { logLogin } from '@/actions/aloader';

import { getAccountUrl } from '@/utils'

import i18n from '@/i18n'; 
import { Button, Alert, Box, Input, Typography, Divider } from '@mui/joy'
import { TextField } from '@/components/InputField';
import { Warning } from '@phosphor-icons/react';
import { Check } from '@phosphor-icons/react';
import { useLoadSession } from '@/contexts';
import { useEffect } from 'react';

export function LoginPage() {

  const navigate = useNavigate()
  const [loggingIn, setLoggingIn] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const loadSession = useLoadSession()
  const [loggedIn, setLoggedIn] = useState(false)
 
  const handleSubmit = (event) => {
    event.preventDefault()
    setLoggingIn(true)
    localStorage.setItem('email', email);
    createMagicLink(email).then((data) => {
      setError(null)
      setLoggingIn(false)
      navigate(`/accounts/login/link/${data.id}`);
    }).catch(e => {
      console.error(e)
      setLoggingIn(false)
      setError(e)
    })
  }
  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Box sx={{ padding: 1 }}>
        <Box style={{ display: 'flex', gap: 10, width: '100%', alignItems: 'stretch', flexDirection: "column"}}>
          {error &&
            <Alert variant="filled" color="danger">
              <Warning size={28} />
              {i18n.t('invalid-username-or-password')}
            </Alert>
          }
          {loggedIn &&
            <Alert variant="filled" color="success">
              <Check size={28} />
              {i18n.t('successfully-logged-in')}
            </Alert>
          }
          <TextField placeholder={i18n.t("email")} onChange={(event) => setEmail(event.target.value)} name="email" variant="standard" label={i18n.t("email")} required={true} />
          <Button loading={loggingIn} type="submit" variant="solid" color="primary">{loggingIn ? i18n.t('loading') : i18n.t('login-with-link')}</Button>
          <Divider sx={{ '--Divider-childPosition': '50%' }}>{i18n.t('or')}</Divider>
          <Button variant="outlined" onClick={() => navigate(`/accounts/login/password`)}>{i18n.t('login-with-password')}</Button>
        </Box>
      </Box>
    </form>
  )
}

LoginPage.action = async ({ request, params }) => {
  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  await loginWithUsernameAndPassword(params.contactId, updates);
  return router.push(`/dashboard/overview`);
}

export default LoginPage;
