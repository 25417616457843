import React, { forwardRef } from 'react';

import cx from 'classnames'

import { NavLink as NavLinkBase } from 'react-router-dom'; 

export const NavLink = forwardRef((props, ref) => (
  <NavLinkBase
    ref={ref}
    {...props}
    selected={props.selected}
  />
));