import React, { useState } from 'react';

import { gql, useSuspenseQuery } from '@apollo/client';
import { useHostname } from '@/contexts';

import { Loading } from '@/components/Loading';

import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@/components/Skeleton';

import i18n from '@/i18n';
import { useSearchParams } from 'react-router-dom' 
import { Avatar, Box, Grid, Input, IconButton, Table, Typography, Menu, MenuButton, MenuItem, Select } from '@mui/joy';
import { Button, Container } from '@mui/joy';
import { Eye, MagnifyingGlass, Pencil, Plus, Trash } from '@phosphor-icons/react';
import { LabelCard } from '@/components/LabelCard';
import { Link } from 'react-router-dom';
import { ViewTypeSelect } from '@/components/ViewTypeSelect';
import { useLocalStorage, useResource } from '@/hooks';
import { Case, Do, Empty, ForEach, SwitchCase } from '@/components/Syntax';
import { Dropdown } from '@mui/joy';
import { MoreHoriz } from '@mui/icons-material';
import { useEffect } from 'react';
import { GenericList } from '@/components/GenericList';

const QUERY = gql`
  query getLabels($hostname: String!, $q: String) {
    me {
      id
      label(domain: $hostname) {
        id
        labels(q: $q, page: 0, limit: 5) {
          name
          id
          numberOfReleases
          imageUrl
        } 
      }
    }
  }
`

const CREATE_LABEL_MUTATATION = gql`
  mutation createLabel(
    $labelId: ID!,
    $name: String
  ) {
    createLabel(
      labelId: $labelId,
      name: $name
    ) {
      label {
        id
        imageUrl
        name
      }
    }
  }
`

export function LabelsPage() {
  return (
    <LabelsView />
  )
}

export function LabelsPageOld() {
  const query = useSearchParams()
  const navigate = useNavigate()
  const [q, setQ] = useState(query.q)
  const handleFiltering = (value) => {
    setQ(value)
    if (value != q) {
      navigate({ query: {q : value} })
    }
  }

  const [viewType, setViewType] = useLocalStorage('labels:viewType', 'grid')
  const [sortBy, setSortBy] = useLocalStorage('labels:sortBy', 'name')

  return (
    <>
      <Container>
        <Typography level="h1" component="h1">{i18n.t('labels')}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <Button
            variant="solid"
            color="primary"
            onClick={() => navigate(`/dashboard/label/add`)}
            startDecorator={<Plus />}
          >{i18n.t('add-label')}</Button>
          <Input
            startDecorator={<MagnifyingGlass />}
            defaultValue={q}
            onChange={(event) => setQ(event.target.value)}
            placeholder={i18n.t('filter-labels')}
          />
          <Select
            placeholder={i18n.t('sort-by')}
            defaultValue={sortBy}
            onChange={(event) => setSortBy(event.target.value)}
          >
            <option value="name">{i18n.t('name')}</option>
          </Select>
          <ViewTypeSelect viewType={viewType} onChange={(value) => setViewType(value)} />
        </Box>
      </Container>
      <br />
      <React.Suspense fallback={<Loading />}>
        <LabelsView q={q} viewType={viewType} />
      </React.Suspense>
    </>
  )
}

export function LabelsSkeleton() {
  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10}}>
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
      </div>
    </Container>
  )
}

export function LabelsView() {
  return (
    <GenericList
      heading={i18n.t('labels')}
      addUrl={`/dashboard/admin/label/add`}
      addLabel={i18n.t('add-label')}
      emptyText={`No labels found`}
      filterPlaceholder={`filter-labels`}
      path={`/labels`}
      baseUrl={`/dashboard/label`}
      queryKey={['labels']}
    />
  )
}
export function LabelsViewOld({
  q,
  viewType
}) {
  const hostname = useHostname()

  // @ts-ignore
  const { data: labels, refetch } = useResource({
    queryPath: ['labels'],
    path: `/labels`,
    query: {
      q
    }
  })
  useEffect(() => {
    refetch()
  }, [q])

  return (
    <Container>
      <SwitchCase value={viewType}>
        <Case match="grid">
          <Grid container spacing={2} >
            <ForEach collection={labels}>
              <Do>
                {label => (
                  <Grid key={label.id}   md={3}> 
                    <LabelCard component={Link} to={`/dashboard/label/${label.id}`} {...label} />
                  </Grid>
                )}
              </Do>
              <Empty>
                {i18n.t('no-labels-found')}
              </Empty>
            </ForEach>
          </Grid>
        </Case>
        <Case match="list">
          <Table width="100%" sx={{ width: '100%'}}>
            <thead>
              <tr>
                <th>
                  
                </th>
                <th>
                  {i18n.t('name')}
                </th>
                <th>
                  {i18n.t('of-releases')}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ForEach collection={labels}>
                <Do>
                  {label => (
                    <tr key={label.id}>
                      <td width={10}>   
                        <Avatar src={label.image_url} style={{ aspectRatio: '1/1', height: '32pt'}} />
                      </td>
                      <td>
                        <Typography>{label.name || <i>**No name**</i>}</Typography>
                      </td>
                      <td>
                        <Typography>{label.numberOfReleases?.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</Typography>
                      </td>
                      <td style={{ textAlign: 'right'}}>
                        <Dropdown>
                          <MenuButton
                          sx={{ opacity: 0.5 }}
                            slots={{ root: IconButton }}
                            slotProps={{ root: { color: 'neutral' } }}
                          >
                            <MoreHoriz />
                          </MenuButton>
                          <Menu placement="bottom-end">
                            <MenuItem onClick={() => navigate(`/dashboard/label/${label.id}`)}>  
                              <Eye />{i18n.t('view')}
                            </MenuItem>
                            <MenuItem onClick={() => navigate(`/dashboard/label/${label.id}/edit`)}>  
                              <Pencil />{i18n.t('edit')}
                            </MenuItem>
                            <MenuItem sx={{ color: "danger" }} onClick={() => deleteLabel(label.id)}>  
                              <Trash />{i18n.t('delete')}
                            </MenuItem> 
                          </Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )}
                </Do>
                <Empty>
                  <tr colSpan={4}>{i18n.t('no-labels-found')}</tr>
                </Empty>
              </ForEach>
            </tbody>
          </Table>
        </Case>
      </SwitchCase>
      
    </Container>
  )
}

export default LabelsPage