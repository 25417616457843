import React, { useCallback, useState } from 'react';
import { Box, Button, Container } from '@mui/joy';
import { InputField, TextField } from '@/components/InputField';
import { HBox } from '@/components/HBox';

import i18n from '@/i18n'

import { ArtworkField } from '@/components/ArtworkField'; 

import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';
import { useHostname } from '@/contexts';
import { Loading } from '@/components/Loading';
import { useEffect } from 'react';
import { gql, useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ArrowLeft, Check } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useResource } from '@/hooks';
import { RelatedNodeList } from '@/components/RelatedNodeList';

import { db, doc, setDoc } from '@/client';
import { useNavigate } from 'react-router-dom';

const QUERY = gql`
  query getPersonById($personId: ID!, $hostname: String!) {
    me {
      label(domain: $hostname) {
        person(personId: $personId) {
          id
          name
          imageUrl
          spotifyId
          deezerId
          appleMusicId
          tidalId
        }
      }
    }
  }
`

export default function EditPersonPage() {
  const { personId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <EditPersonView personId={personId} />
    </React.Suspense>
  )
}

export function PersonForm({
  person,
  values: defaultValues = {},
  onSubmit,
  onValuesChange = () => console.warn('onValuesChange not implemented')
}) {
  const [values, setValues] = useState(defaultValues)

  const handleSubmit = (event) => {
    event.preventDefault()

    onSubmit(values)
    return false
  }

  useEffect(() => {
    if (person) {
      setValues({
        ...person
      })
    }
  }, [person])

  useEffect(() => {
    onValuesChange(values)
  }, [values])

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <h1>{i18n.t('edit-person')}</h1>
      <Box>
        <TextField
          name="name"
          defaultValue={person.name}
          label={i18n.t('person-name')}
          placeholder={i18n.t("enter-person-name")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        <Button type="submit" startDecorator={<Check />} variant="solid">{i18n.t('save-person')}</Button>
      </Box>
    </form>
  )
}

export function EditPersonView({
  personId
}) {
  const hostname = useHostname()
  const navigate = useNavigate()

  const {
    data: person,
    loading
  } = useResource({ queryKey: ['person'], path: `/person/${personId}`})

  if (loading) {
    return <Loading />
  }

  const handleSubmit = (values) => {
    console.log(values)
    const result = setDoc(doc(db, 'people', personId), {
      ...values
    }).then(() => {
      navigate(`/dashboard/person/${personId}`)
    })
  }

  if (person) {
    return (
      <Container>
        <HBox component={Link}>
          <ArrowLeft />
          <Typography>{person?.name}</Typography>
        </HBox>
        <PersonForm
          person={person}
          onSubmit={handleSubmit}
        />
      </Container>
    )
  }
}