import React from 'react';
import { Cube } from "@phosphor-icons/react";
import { Box } from '@mui/joy';
import { Typography } from '@mui/material';

export function EmptyView({
  Icon=Cube,
  text,
  children
}) {
  return (
  <Box sx={{ aspectRatio: '16/9', width: '100%', background: 'rgba(127, 127, 127, .05)', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 1 }}>
    <Box style={{flex: 1}}></Box>
    <Box sx={{ aspectRatio: '1/1', borderRadius: 100, p: 2, background: 'var(--brand-primary-background)'}}>
      <Icon weight="bold" color="var(--brand-primary-color)" size={88} />
    </Box> 
    <Typography>{text}</Typography>
    {children}
    <Box style={{flex: 1}}></Box>
  </Box>
  )
}