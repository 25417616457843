import React, { useCallback, useState } from 'react';
import { Box, Button, Container } from '@mui/joy';
import { InputField, TextField } from '@/components/InputField';
import { Link as JoyLink } from '@mui/joy'

import i18n from '@/i18n'

import { ArtworkField } from '@/components/ArtworkField'; 

import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';
import { useHostname } from '@/contexts';
import { Loading } from '@/components/Loading';
import { useEffect } from 'react';
import { gql, useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ArrowLeft, Check, Plus } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useResource } from '@/hooks';
import { RelatedNodeList } from '@/components/RelatedNodeList';

import { db, doc, setDoc } from '@/client';
import { useNavigate } from 'react-router-dom';
import { getSpotifyId } from '@/utils';
import { uploadCoverArtToArtist } from '@/actions/login';

const QUERY = gql`
  query  getArtistById($artistId: ID!, $hostname: String!) {
    me {
      label(domain: $hostname) {
        artist(artistId: $artistId) {
          id
          name
          imageUrl
          spotifyId
          deezerId
          appleMusicId
          tidalId
        }
      }
    }
  }
`

export default function EditArtistPage() {
  const { artistId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <EditArtistView artistId={artistId} />
    </React.Suspense>
  )
}

export function ArtistForm({
  artist,
  values: defaultValues = {},
  submitButtonLabel=i18n.t("add-artist"),
  onSubmit,
  onValuesChange = () => console.warn('onValuesChange not implemented')
}) {
  const [values, setValues] = useState(defaultValues)

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(values)
    return false
  }

  useEffect(() => {
    if (artist) {
      setValues({
        ...artist
      })
    }
  }, [artist])

  useEffect(() => {
    onValuesChange(values)
  }, [values])

  return (
    <form key={artist?.id} method="POST" onSubmit={handleSubmit}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <TextField
          name="name"
          required
          defaultValue={artist?.name}
          label={i18n.t('artist-name')}
          placeholder={i18n.t("enter-artist-name")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        {artist && <ArtworkField
          label={i18n.t('picture')}
          imageUrl={values?.image_url}
          onChange={(value) => setValues({ ...values, image: value })}
        />}
        <TextField
          defaultValue={artist?.spotifyId ? `https://open.spotify.com/artist/${artist.spotifyId}` : ''}
          label={i18n.t('spotify-artist-url')}
          name="spotify_url"
          pattern="^(https:\/\/open.spotify.com\/artist\/([a-zA-Z0-9]+)[23](\?(.*))?"
          placeholder="https://open.spotify.com/artist/id"
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value, spotifyId: getSpotifyId(event.target.value) })}
        />
        <SelectRelatedNodes
          label={i18n.t('users')}
          nodeType="user"
          addLabel={"add-user"}
          placeholder={i18n.t('add-users')}
          ownerNodeType="artist"
          ownerNodeId={artist?.id}
          allLabel={i18n.t('all-users')}
          ownLabel={i18n.t('artist-s-users')}
          nodes={artist?.members ?? []}
          onChange={(members) => {
            setValues({ ...values, members })
          }}
        />
        <Button type="submit" startDecorator={<Plus />} variant="solid">{submitButtonLabel}</Button>
      </Box>
    </form>
  )
}

export function EditArtistView({
  artistId
}) {
  const hostname = useHostname()
  const navigate = useNavigate()

  const {
    data: artist,
    loading
  } = useResource({ queryKey: ['artist', artistId], path: `/artist/${artistId}`})

  if (loading) {
    return <Loading />
  }

  const handleSubmit = (values) => {
    console.log(values)
    const image = values.image
    setDoc(doc(db, 'artists', artistId), { ...values }).then(() => {
      if (image) {
        uploadCoverArtToArtist(artistId, image).then(() => {
          enqueueSnackbar('Artist successfully saved')
          navigate(`/dashboard/artist/${artistId}`)
        })
      } else {
        enqueueSnackbar('Artist successfully saved')
        navigate(`/dashboard/artist/${artistId}`)
      }
    })
  }

  if (artist) {
    return (
      <Container>
        <JoyLink component={Link}>
          <ArrowLeft />
          <Typography>{artist?.name}</Typography>
        </JoyLink>
        <h1>{i18n.t('edit-artist')}</h1>
        <ArtistForm
          artist={artist}
          onSubmit={handleSubmit}
        />
      </Container>
    )
  }
}