import { createContext, useContext } from "react"

export const AppContext = createContext({
  addProcess: () => {},
  removeProcess: () => {},
  processes: [],
  setProcesses: () => {},
  messages: [],
  setMessages: () => {},
  apolloStatus: null,
  setApolloStatus: () => {},
  navigate: null,
  setNavigate: (value) => {}
})

export function useApp() {
  return useContext(AppContext)
}