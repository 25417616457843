import { useLabel } from '@/contexts';
import React from 'react';
import { useEffect } from 'react';

import { Outlet } from 'react-router-dom';

export default function Layout({ children }) {

  const label = useLabel()

  const handleFavIcon = () => {
    if (label && label.is_branded) {
      if (label.favicon_url) { 
        let link = document.querySelector("link[rel~='icon']");
        if (link) {
          link.parentNode.removeChild(link)
          link = null
        } 
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
        link.href = label.favicon_url;
      }
      if (label.name) {
        let titleEl = document.querySelector("title");
        if (!titleEl) {
          titleEl = document.createElement('title');
          document.getElementsByTagName('head')[0].appendChild(titleEl);
        }
        titleEl.innerHTML = label.name
      }
    }
  }

  useEffect(handleFavIcon, [location])
  useEffect(handleFavIcon, [label])

  return (
    <Outlet /> 
  );
}