import React, { useState } from 'react';

import { Button, Table, Modal, ModalDialog, Box, ModalClose, Avatar, Switch } from '@mui/joy'

import { InputField, InputFieldFrame } from './InputField';
import { SearchNodeList } from './SearchNodeList';

import i18n from '@/i18n'

import { Img } from './Img';

import { isSubject } from '@/utils';
import { useEffect } from 'react';
import { SelectRelatedNode } from './SelectRelatedNode';
import { CreateNodeForm } from './CreateNodeForm';
import { Typography } from '@mui/material';
import { HBox } from './HBox';

export function NodeCell({ column, node }) {
  console.log(node)
  switch (column.type) {
  case "belongsTo":
    return (
      node[column.id] instanceof Array ? (
        node[column.id].map(n => n.name).join(', ')
      ) : node[column.id] instanceof Object ? (
        node[column.id].name
      ) : ''
    )
  case "belongsToMany":
    return (
      node[column.id] instanceof Array ? (
        node[column.id].map(n => {
          if (column.render instanceof Function) {
            return column.render({ value: n, node })
          } else {
            return n.name
          }
        })
      ) : node[column.id] instanceof Object ? (
        column.render instanceof Function? column.render({ node, value: node[column.id] }) : node[column.id].name
      ) : ''
    )
  case "percentage":
    return (
      <Typography sx={{ textAlign: 'right', marginRight: 20 }}>{(node[column.id] ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %</Typography>
    )
  case "bool":
    return (
      <HBox>
        <Switch readOnly={readOnly} defaultChecked={node[column.id]} /> {node[column.id] ? (column.onLabel ?? column.label) : (column.offLabel ?? column.label)}
      </HBox>
    )
  default:
    return (
      node[column.id] instanceof Array ? (
        node[column.id].map(n => n.name).join(', ')
      ) : node[column.id] instanceof Object ? (
        node[column.id].name
      ) : node[column.id]
    )
  }
}

export function SelectRelatedNodes({
  label,
  fields,
  heading,
  createLabel,
  allLabel,
  addLabel,
  ownLabel,
  showIndexes = false,
  placeholder,
  errors,
  canCreate=false,
  nodes,
  columns = [],
  emptyText,
  attributes = [],
  onChange,
  readOnly,
  nodeType,
  ownerNodeType,
  ownerNodeId
}) {
  const [isAddingNodes, setAddingNodes] = useState(false)
  const [selectedNodes, setSelectedNodes] = useState(nodes)
  
  useEffect(() => {
    setSelectedNodes(nodes)
  }, [nodes])
  const handleChange = (nodes) => {
    setSelectedNodes(nodes)
  }

  const handleSubmitSetNodes = (selectedNodes) => {
    onChange(selectedNodes)
    setAddingNodes(false)
  }

  return (
    <>
      <InputField
        label={label}
        errors={errors}
      >
        <InputFieldFrame sx={{ padding: '16px 16px' }}>
          <Table
            sx={{ width: '100%', 'td': { p: 0} }}
          >
            <tbody>
              {selectedNodes?.length > 0 ?
                <>
                  {selectedNodes?.map((node, i) => (
                    <tr key={node.id}>
                      {showIndexes && (
                        <>
                          <td style={{ textAlign: 'right', opacity: 0.5, width: 40, padding: 8 }}>
                            <Typography sx={{ fontSize: 20}}>{(i + 1).toLocaleString().padStart(2, '0')}</Typography>
                          </td>
                        </>
                      )}
                      <td style={{ width: 50, padding: 1 }}>
                        {isSubject(nodeType) ?
                          <Avatar src={node[nodeType].thumbnail_url} size="sm" style={{ marginRight: 10 }} />
                        :
                          <Img src={node[nodeType].thumbnail_url} style={{ height: '30pt', marginRight: 10 }} />
                        }
                      </td>
                      <td>{node[nodeType]?.name}</td>
                      {columns?.map(column => <td key={column.id}><NodeCell column={column} node={node[nodeType]} /></td>)}
                      {attributes.map(column => <td key={column.id}><NodeCell column={column} node={node} /></td>)}
                    </tr>
                  ))}
                </>
              :
                <tr>
                  <td colSpan={2}>
                    <Typography sx={{ fontFamily: "DM Mono" }}>{placeholder}</Typography>
                  </td>
                </tr>
              }
            </tbody>
          </Table>
          {!readOnly && <Box sx={{  display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setAddingNodes(true)}
            >
              {i18n.t(nodes?.length > 0 ? 'edit' : 'add')}
            </Button>
          </Box>}
        </InputFieldFrame>
      </InputField>
      <Modal open={isAddingNodes} onClose={() => setAddingNodes(false)}>
        <ModalDialog>
          <ModalClose />
          <SearchNodeList
            columns={columns}
            createLabel={createLabel}
            fields={fields}
            nodeType={nodeType}
            onClose={() => setAddingNodes(false)}
            attributes={attributes}
            emptyText={emptyText}
            isSubject={isSubject}
            canCreate={canCreate}
            showIndexes={showIndexes}
            selectedNodes={selectedNodes}
            allLabel={allLabel}
            addLabel={addLabel}
            ownLabel={ownLabel}
            onChange={handleChange}
            onSubmit={handleSubmitSetNodes}
          />
        </ModalDialog>
      </Modal>
    </>
  )
}