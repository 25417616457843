import React from 'react';

import i18n from '@/i18n';

import { Card, CardTitle, CardContent, CardMetaData } from './Card';

import { Img } from './Img';
import { Disc, Microphone, Smiley } from '@phosphor-icons/react';

export function ArtistCard({
  name,
  image_url,
  thumbnail_url,
  number_of_releases = 0,
  ...props
}) {
  return (
    <Card sx={{ padding: 0}} {...props}>
      <Img PlaceholderIcon={Smiley} src={thumbnail_url || image_url} sx={{ aspectRatio: '1/1' }} />
      <CardContent sx={{ padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        <CardTitle>{name}</CardTitle>
        <CardMetaData>{i18n.t('x-releases', { numberOfReleases: number_of_releases })}</CardMetaData>
      </CardContent>
    </Card>
  )
}