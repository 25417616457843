import { Box, Typography } from '@mui/joy';
import { Warning } from '@phosphor-icons/react';
import React from 'react';

export function ErrorView({
  
}) {
  return (
    <Box sx={{ aspectRatio: '16/9', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
      <Warning size={50} />
      <Typography>Oops! We encountered an error</Typography>
    </Box>
  )
}