import React, { useState, useEffect } from 'react'

import { Typography } from '@mui/joy'

import i18n from '@/i18n'

import { TextField } from './InputField'
import { VBox } from './VBox'
import { HBox } from './HBox'
import { checkISRCCode } from '@/actions/aloader'

export function ISRCField({
    name,
    label,
    readOnly,
    required,
    defaultValue,
    placeholder,
    onChange = () => {},
    onError = () => {},
    errors: defaultErrors,
    ...props
}) {    const [valid, setValid] = useState(true)

    const [checking, setChecking] = useState(false)
    const [error, setError] = useState(null)
    const [reason, setReason] = useState('unknown')

    const [errors, setErrors] = useState(defaultErrors)

    useEffect(() => {
        setErrors(defaultErrors)
    }, [defaultErrors])
    
    useEffect(() => {
        if (!valid) {
            setValid(false)
        } else {
            setValid(true)
        }
        onError(error)
    }, [error])

    return (
        <VBox>
            <TextField
                readOnly={readOnly}
                name={name}
                errors={errors}
                required={required}
                label={label}
                placeholder={placeholder}
                pattern="^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$"
                defaultValue={defaultValue}
                onChange={(event) => {
                    onChange(event)
                    setReason('checking')
                    setValid(false)
                    setError(null)
                    const value = event.target.value
                    if (value?.length > 0) {
                        if (!/^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/.test(value)) {
                            setError({
                                field: name,
                                description: i18n.t('isrc.error.invalid.description'),
                                type: 'invalid'
                            })
                            return
                        }
                        checkISRCCode(value).then(result => {
                            setReason(result.reason)
                            setValid(result.valid)

                            if (!result.valid) {
                                setError({
                                    field: name,
                                    description: i18n.t('isrc.error.' + result.reason + '.description'),
                                    type: reason
                                })
                            } else {
                                setError(null)
                            }
                            setChecking(false)
                        }).catch(e => {
                            setReason('error')
                            setValid(false)
                            setError({
                                field: name,
                                description: i18n.t('isrc.error.error.description'),
                                type: 'error'
                            })
                            setChecking(false)
                        })
                    } else {
                        if (required) {
                            setReason("empty")
                            setValid(false)
                            setError({
                                field: name,
                                description: 'isrc.error.empty.description',
                                type: 'empty'
                            })
                        } else {
                            setReason("optional")
                            setValid(true)
                            setError(null)
                        }
                    }
                }}
                {...props}
            />
            {checking && (
                <HBox>
                    <Typography>{i18n.t('checking-isrc-code')}</Typography>
                </HBox>
            )}
        </VBox>
    )
}