import React from 'react';

import i18n from '@/i18n'

import { useWindowSize } from '@uidotdev/usehooks';
import { Box, Button, Grid, Option, Select, Typography, useTheme } from '@mui/joy';
import { VBox } from './VBox';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { useState } from 'react';
import { HighlightedNumber } from './HighlightedNumber';
import { useSearchParam } from '@/hooks';
import { HBox } from './HBox';
import { ToggleButton } from './ToggleButton';
import { LineSeries } from './LineSeries';
import { ToggleButtonBox } from './ToggleButtonBox';
import { BoxArrowDown } from '@phosphor-icons/react';
import { useEffect } from 'react';
import moment from 'moment';
import { VictoryArea, VictoryAxis, VictoryBar, VictoryChart, VictoryLine, VictoryTooltip } from 'victory';
import { FlyOut } from './LineSeries/FlyOut';
import { useMemo } from 'react';

export function Statistics({
    start,
    end,
    period,
    data,
    dataKey
}) {
    const theme = useTheme()
    const { width, height } = useWindowSize()
    
    const maxValue = useMemo(() => {
        let max = 0 
        if (!data?.stats) {
            return max
        }
        for (let value of data.stats) {
            max = Math.max(max, parseFloat(value[dataKey] ?? 0) ?? 0)
        }
        return max
    }, [data?.stats]) 

    const [zoomDomain, setZoomDomain] = useState({ x: [moment(start).toDate(), moment(end).toDate()], y: [0, maxValue] })

    useEffect(() => {
        setZoomDomain({ x: [moment(start).format(), moment(end).toDate()], y: [0, maxValue] })
      }, [start])
    
      useEffect(() => {
        setZoomDomain({ x: [moment(start).toDate(), moment(end).toDate()], y: [0, maxValue] })
      }, [end])
    if (!data?.stats) {
        return <Typography>No stats</Typography>
    }

    return (
        <VictoryChart 
            responsive={true}
            zoomDomain={zoomDomain}
            scale={{ x: "time" }} 
            height={250}
        >
            <VictoryAxis
                fixLabelOverlap={true}
                style={{
                    axisLabel: {
                        fontSize: 10
                    },
                    tickLabels: {
                        fontSize: 6,
                        fontFamily: "DM Sans",
                        fill: 'black'
                    }
                }}
            />
            <VictoryAxis
                fixLabelOverlap={true}
                dependentAxis
                style={{
                    grid: { stroke: 'rgba(127, 127, 127, .3)', strokeWidth: 0.5 },
                    axisLabel: {
                        fontSize: 5
                    },
                    tickLabels: {
                        fontSize: 6,
                        fontFamily: "DM Sans",
                        fill: 'black'
                    }
                }}
            />
            <VictoryArea
                interpolation="linear" 
                data={data.stats} 
                x={(value) => value.date}
                y={(value) => value[dataKey]}
                style={{
                  data: { stroke: theme.vars.palette.primary.solidBg, fill: `color-mix(in srgb, ${theme.vars.palette.primary.solidBg}, transparent 50%)`, strokeWidth: 1 }
                }}
                
            />
 
             <VictoryBar
                labels={(o) => o[dataKey] ?? 0} 
                labelComponent={
                    <VictoryTooltip
                        renderInPortal={true}
                        flyoutComponent={<FlyOut />}
                        flyoutStyle={{
                            fill: theme.vars.palette.primary.solidBg,
                        }}
                        style={{
                        fill: 'white',
                        }} 
                    />
                }
                style={{
                    data: { transform: 'translateX(30pt)', stroke: 'transparent', fill: "transparent" }
                }}
                barWidth={110}
                barRatio={0}
                
                interpolation="linear" 
                data={data.stats} 
                x={(value) => value.date}
                y={(value) => value[dataKey]}
            >
                <VictoryTooltip
                    center={{ x: 225, y: 30 }}
                    pointerOrientation="bottom"
                    flyoutWidth={150}
                    flyoutHeight={50}
                    pointerWidth={150}
                    cornerRadius={0}
                />
            </VictoryBar> 
        </VictoryChart>
    )

    return ( 
        <LineSeries
            width={width}
            height={height * 0.5}
            start={start}
            dataKey={dataKey}
            end={end}
            series={[
                { 
                    x: {
                        field: {
                            id: 'date',
                            name: i18n.t('date')
                        }
                    },
                    y: [
                        {
                            field: {
                                id: dataKey,
                                name: i18n.t(dataKey)
                            }
                        }
                    ],
                    label: i18n.t('gross'),
                    values: data.stats
                }
            ]}
        /> 
    )
}