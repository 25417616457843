import React from 'react';

import moment from 'moment';

import { Card, Chip, Box, CardContent, Typography } from '@mui/joy';

import i18n from '@/i18n';
import { Img } from './Img';
import { Disc } from '@phosphor-icons/react';
import { MusicNote } from '@phosphor-icons/react/dist/ssr';

export function RecordingCard({
  name,
  thumbnailUrl,
  imageUrl,
  hasPreRelease,
  released,
  isrc,
  releaseType,
  ...props
}) {
  return (
    <Card sx={{ padding: 0}} {...props}>
      <Img PlaceholderIcon={MusicNote} src={thumbnailUrl || imageUrl} sx={{ aspectRatio: '1/1' }} />
      <CardContent sx={{ padding: 1, paddingTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>

        <Typography s>{isrc ?? '-'}</Typography>
        <Typography sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: '800',
          overflow: 'hidden'
        }} level="h4">{name}</Typography> 
      </CardContent>
    </Card>
  )
}