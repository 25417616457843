
import React from 'react'

import { styled, Box } from '@mui/joy'

export const ToggleButtonBox = styled(
    Box, {
      name : 'ToggleButtonBox',
      slot: 'root'
    }
  )(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    background: theme.vars.palette.background.surface,
    borderColor: theme.vars.palette.primary.solidBg,
    borderWidth: 2,
    borderStyle: 'solid'
  }))