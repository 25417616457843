import React, { useEffect, useState } from 'react';

import { styled } from '@mui/joy';
import { Box } from '@mui/material';
import { ListDashes, SquaresFour } from '@phosphor-icons/react';

import { ToggleButton } from './ToggleButton';

import i18n from '@/i18n'

export const ViewTypeSelectBox = styled(
  Box, {
    name : 'ViewTypeSelectBox',
    slot: 'root'
  }
)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  background: theme.vars.palette.background.surface,
  borderColor: theme.vars.palette.primary.solidBg,
  borderWidth: 2,
  borderStyle: 'solid'
}))

export function ViewTypeSelect({
  viewType: defaultViewType,
  onChange
}) {
  const [viewType, setViewType] = useState(defaultViewType)
  useEffect(() => {
    onChange(viewType)
  }, [viewType])
  return (
    <ViewTypeSelectBox>
      <ToggleButton onClick={() => setViewType('grid')} active={viewType === 'grid'} startDecorator={<SquaresFour weight="bold" style={{ margin : 0}} size={15} />}>{i18n.t('grid')}</ToggleButton>
      <ToggleButton onClick={() => setViewType('list')} active={viewType === 'list'} startDecorator={<ListDashes weight="bold" size={15} />}>{i18n.t('list')}</ToggleButton>
    </ViewTypeSelectBox>
  )
}