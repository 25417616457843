import React from 'react';

import { gql } from '@apollo/client';

import localforage from 'localforage';

import client, { authorizedCoreFetch, authorizedFetch } from '@/client';
import { getAPIUrl } from '@/utils';

export async function downloadStatistics({ query, labelName, artistName = '', releaseName = '', recordingName = '' }) {
  const { saveAs } = await import('save-as'); 

  //window.open(`${getAPIUrl()}/api/statistics.csv?${querystring}&access_token=${token}`, '_blank')
  //return
  authorizedCoreFetch('GET', `/statistics.csv`, {}, { ...query }).then( res => res.blob() )
  .then( blob => {
    //document.write(blob) 
    var file = new File([blob], `Statistics_${labelName}_${artistName}_${releaseName}_${recordingName}_${query.start}-${query.end}.csv`)
    const fileUrl = window.URL.createObjectURL(file); 
    saveAs(blob, `Statistics_${labelName}_${query.start}-${query.end}.csv`)
  });
}

export async function uploadImageToLabel({ labelId, file }) {
  const token = await localforage.getItem('token');
  var data = new FormData()
  data.append('file', file)
  const response = await fetch(
    `${getAPIUrl()}/api/v2/labels/${labelId}/image`,
    {
      body: data,
      method: 'POST',
      headers: {
        'Authorization': `JWT ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
  const result = await response.json()
  return result
}

export async function checkISRCCode(isrc) {
  return await authorizedFetch('GET', `/check/isrc`, {}, { isrc })
}

export async function submitReleaseForReview(releaseId) {
  return await authorizedFetch('POST', `/release/${releaseId}/distribute`)
}

export async function logLogin({
  username,
  success
}) {
  return await authorizedFetch('POST', `/logins`, {
    username,
    success
  })
}

export async function updateLabel(data) {
  const labelId = data.id
  return authorizedFetch('PUT', `/labels/${labelId}`, {
    body: JSON.stringify(data)
  })
}

export async function createArtist({
  name,
  email,
  username,
  spotifyId,
  labelId
}) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createArtist( 
        $name: String!,
        $username: String!,
        $email: String!,
        $labelId: ID!,
        $spotifyId: String
      ) {
        createArtist(
          name: $name,
          username: $username,
          email: $email,
          labelId: $labelId,
          spotifyId: $spotifyId
        ) {
          artist {
            id
            name
          }
        }
      }
    `,
    variables: {
      name,
      email,
      username,
      spotifyId,
      labelId
    }
  })

  return data.createArtist.artist
}

export async function createPitch({
  trackId,
  pressText,
  pressKitUrl
}) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createPitch( 
        $trackId: ID!,
        $pressText: String!,
        $pressKitUrl: String!
      ) {
        createPitch(
          trackId: $trackId,
          pressText: $pressText,
          pressKitUrl: $pressKitUrl
        ) {
          pitch {
            id
            pressText
          }
        }
      }
    `,
    variables: {
      trackId,
      pressText,
      pressKitUrl
    }
  })
  return data.createPitch.pitch
}

export async function syncReleaseDownstream({ labelId, releaseId }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation syncReleaseDownstream(
        $labelId: ID!
        $releaseId: ID!
      ) {
        syncReleaseDownstream(
          labelId: $labelId,
          releaseId: $releaseId
        ) {
          release {
            id
          }
        }
      }
    `,
    variables: {
      labelId,
      releaseId
    }
  })
  return data.syncReleaseDownstream
}

export async function inviteUser({
  label_id,
  email,
  user_artists
}) {
  const token = await localforage.getItem('token');
  var data = new FormData() 
  const response = await fetch(
    `${getAPIUrl()}/api/v2/labels/${label_id}/invitations`,
    {
      body: JSON.stringify({
        email,
        user_artists
      }),
      method: 'POST',
      headers: {
        'Authorization': `JWT ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
  const result = await response.json()
  return result
}

export async function redeemInvitation({
  invitationId
}) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation redeemInvitation( 
        $invitationId: ID!
      ) {
        redeemInvitation(
          invitationId: $invitationId
        ) {
          artist {
            id
          }
        }
      }
    `,
    variables: {
      invitationId
    }
  })
  return data.redeemInvitation.artist
}

export async function fetchAudioProcessingStatus(recordingId) {
  const res = await authorizedFetch('GET', `/recording/${recordingId}/audio-processing-status`)
  return data.fetchAudioProcessingStatus

}

export async function getPitchById(pitchId) {
  const { data } = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query getPitchById($pitchId: ID!) {
        me {
          pitch(pitchId: $pitchId) {
            id
            pressText
            pressKitUrl
            trackId
            track {
              id
              name
            }
          }
        }
      }
    `,
    variables: {
      pitchId
    }
  })
  return data.me.pitch
}

export async function removePitch(pitchId) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation removePitch( 
        $pitchId: ID!
      ) {
        removePitch(
          pitchId: $pitchId
        ) {
          ok
        }
      }
    `,
    variables: {
      pitchId
    }
  })
  return data.createPitch.pitch
}

export async function createRelease({ name, labelId, artistId }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createRelease($name: String!, $labelId: ID!, $artistId: ID) {
        createRelease(name: $name, labelId: $labelId, artistId: $artistId) {
          release {
            id
            name
            label {
              id
              name
            }
          }
        }
      }
    `,
    variables: {
      name,
      labelId
    }
  })
  return data.createRelease.release
}

export async function deleteRelease({ labelId, releaseId }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createRelease($labelId: ID!, $releaseId: ID) {
        deleteRelease(labelId: $labelId, releaseId: $releaseId) {
          ok
        }
      }
    `,
    variables: {
      releaseId,
      labelId
    }
  })
  return data.deleteRelease
}

export async function createCategory({ labelId, name }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createCategory($labelId: ID!, $name: String!) {
        createCategory(labelId: $labelId, name: $name) {
          ok
        }
      }
    `,
    variables: {
      name,
      labelId
    }
  })
  return data.createCategory
}

export async function deleteVideo({ labelId, videoId }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation deleteVideo($labelId: ID!, $videoId: ID) {
        deleteVideo(labelId: $labelId, videoId: $videoId) {
          ok
        }
      }
    `,
    variables: {
      videoId,
      labelId
    }
  })
  return data.deleteVideo
}

export async function createVideo({ labelId, name, embedUrl, description }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createVideo($labelId: ID!, $name: String!, $embedUrl: String!, $description: String) {
        createVideo(labelId: $labelId, name: $name, embedUrl: $embedUrl, description: $description) {
          video {
            id
            name
          }
        }
      }
    `,
    variables: {
      labelId,
      name,
      embedUrl,
      description
    }
  })
  return data.deletRelease
}

export async function deleteCategory({ labelId, categoryId }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createCategory($labelId: ID!, $categoryId: ID) {
        deleteCategory(labelId: $labelId, categoryId: $categoryId) {
          ok
        }
      }
    `,
    variables: {
      categoryId,
      labelId
    }
  })
  return data.deletVCategory
}

export async function removeTracksFromRelease(trackIds) {
  return await client.mutate({
    mutation: gql`
      mutation removeTracksFromRelease($trackIds: [ID]!) {
        removeTracksFromRelease(trackIds: $trackIds) {
          recordings {
            id
            name
          }
        }
      }
    `,
    variables: {
      trackIds
    }
  })
}

export async function removeReleases(releaseIds) {
  return await client.mutate({
    mutation: gql`
      mutation removeRelease($releaseIds: [ID]!) {
        removeReleases(releaseIds: $releaseIds) {
          ok
        }
      }
    `,
    variables: {
      releaseIds
    }
  })
}
