import React from 'react';

import { Box, CircularProgress } from '@mui/joy';

export function Loading({
  children
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row',  justifyContent: 'center', alignItems: 'center', width: '100%', aspectRatio: '16/9' }}>
      <CircularProgress />
    </Box>
  )
}