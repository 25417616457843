import React from 'react';
import { Box, Button, Container } from '@mui/joy';
import { TextField } from '@/components/InputField';

import i18n from '@/i18n';
import { useState } from 'react';
import { ArtworkField } from '@/components/ArtworkField';

import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';
import { useHostname } from '@/contexts';
import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';
import { Check } from '@phosphor-icons/react';
import { db, collection, addDoc } from '@/client';
import { useNavigate } from 'react-router-dom';
import { ArtistForm } from './[artistId]/edit';

export default function AddArtistPage() {
  const { artistId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <AddArtistView artistId={artistId} />
    </React.Suspense>
  )
}

export function AddArtistView({
  artistId
}) {
  const [values, setValues] = useState({})

  const navigate = useNavigate()

  const handleSubmit = (values) => {
    const artist = addDoc(collection(db, 'artists'), values).then((artist) => {
      console.log(artist)
      navigate(`/dashboard/artist/${artist.id}`)
    }).catch(e => {
      console.error(e)
    })
  }

  return (
    <Container>
      <h1>{i18n.t('add-artist')}</h1>
      <ArtistForm
        onSubmit={handleSubmit}
        artist={null}
        values={{}}
      />
    </Container>
  )
}