import { gql } from '@apollo/client';

import { getAPIUrl, getAccountUrl } from '@/utils';

import localForage from 'localforage';

import client, { authorizedFetch } from '../client';

export async function getCurrentUser() {
  try {
    const data = await authorizedFetch(
      'GET',
      `/users/me`
    )
    return data
  } catch (e) {
    console.error(e)
    throw e;
  }
}

export async function startLogin() {
  const {
    protocol,
    hostname,
    port,
    pathname,
    search
  } = window.location
  const redirectTo = `${protocol}//${hostname}:${port}${pathname}?${search}`
  await localForage.setItem('redirectTo', redirectTo)
  const url = '/accounts/login' // `${process.env.NEXT_PUBLIC_LOGIN_URL}?response_type=code&client_id=${process.env.NEXT_PUBLIC_CLIENT_ID}&redirect_uri=${encodeURIComponent(process.env.NEXT_PUBLIC_REDIRECT_URI)}`
  setTimeout(
    () => {
      window.location.href = url
    },
    500
  )
}

export async function generateTokenFromAuthorizationCode(code) {
  try {
    const response = await fetch(
      `${getAPIUrl()}/auth/api/token`,
      {
        method: 'POST',
        body: JSON.stringify({
          code
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await response.json()
    return data?.tokenAuth
  } catch (e) {
    throw e
  }
}

export async function deleteArtistUsers({
  labelId,
  artistIds
}) {
  try {
    const { data } = await client.mutate({
      mutation: gql`mutation deleteArtistUsers($artistIds: [ID]!, $labelId: ID!) {
        deleteArtistUsers(artistIds: $artistIds, labelId: $labelId) {
          ok
        }
      }`,
      variables: {
        labelId,
        artistIds
      }
    }) 
    return data.deleteArtistUsers
  } catch (e) {
    throw e
  }
}

export async function deleteLabelUsers({
  labelId,
  labelUserIds
}) {
  try {
    const { data } = await client.mutate({
      mutation: gql`mutation deleteLabelUsers($labelUserIds: [ID]!, $labelId: ID!) {
        deleteLabelUsers(labelUserIds: $labelUserIds, labelId: $labelId) {
          ok
        }
      }`,
      variables: {
        labelId,
        labelUserIds
      }
    }) 
    return data.deleteLabelUsers
  } catch (e) {
    throw e
  }
}

export async function getLabelByDomain(domain, subDomain) {
  const response = await fetch(
    `${getAPIUrl()}/api/labels?domain=${domain}&subDomain=${subDomain}`
  )
  const data = await response.json()
  return data
}

export async function generateTokenFromUsernameAndPassword(username, password) {
  try {
    const response = await fetch(
      `${getAPIUrl()}/api/token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          password
        })
      }
    )
    if (response.status !== 200) {
      throw new Error("Invalid login")
    }
    const data = await response.json()
    return {
      token: data.access,
      refresh: data.refresh
    }
  } catch (e) {
    throw e
  }
}

export async function loginWithAuthorizationCode(code) {
  const result = await generateTokenFromAuthorizationCode(code)
  const { token } = result
  await localForage.setItem('token', token)
  const user = await getCurrentUser()
  let session = {
    user,
    token
  }
  await localForage.setItem('session', session)
  return session
}

export async function loginWithUsernameAndPassword(username, password) {
  const result = await generateTokenFromUsernameAndPassword(username, password)
  const { token } = result
  await localForage.setItem('token', token)
  const user = await getCurrentUser()
  let session = {
    user,
    token
  }
  await localForage.setItem('session', session)
  return session
}

export async function loginWithMagicLink(token) {
  const result = await generateTokenFromMagicLink(token)
  const { access: accessToken, refresh } = result
  await localForage.setItem('token', accessToken)
  const user = await getCurrentUser()
  let session = {
    user,
    token: accessToken,
    refresh
  }
  await localForage.setItem('session', session)
  return session
}

export async function loginWithOTP(token) {
  const result = await generateTokenFromOTP(token)
  const { access: accessToken } = result
  await localForage.setItem('token', accessToken)
  const user = await getCurrentUser()
  let session = {
    user,
    token: accessToken
  }
  await localForage.setItem('session', session)
  return session
}

export async function beginPasswordReset(email) {
  const formData = new FormData()
  formData.set('email', email)
  try {
    const res = await fetch(
      `${getAccountUrl()}/users/reset_password/`,
      {
        method: 'POST',
        cors: 'no-cors',
        body: formData
      }
    ) 
    const data = await res.json()
    return data
  } catch (e) {
    throw e
  }
}

export async function resetPassword({ uid, token, newPassword, reEnterNewPassword }) {
  const formData = new FormData()
  formData.set('uid', uid)
  formData.set('token', token)
  formData.set('new_password', newPassword)
  formData.set('re_enter_new_password', reEnterNewPassword)
  try {
    const res = await fetch({
      url: `${getAccountUrl()}/users/reset_password_confirm/`,
      method: 'POST',
      body: formData
    })  
    const data = await res.json()
    return data
  } catch (e) {
    throw e
  }
}

export async function logout() {
  await localForage.clear()
  indexedDB.deleteDatabase('keyvaluepairs')
  indexedDB.deleteDatabase('local-forage-detec-blob-support')
}

export async function loginWithBearerToken(token) {
  await localForage.setItem('token', token)
  const user = await getCurrentUser()
  let session = {
    user,
    token
  }
  await localForage.setItem('session', session)
  return session
}

export async function getSession() {
  const session = await localForage.getItem('session')

  return session
}

export async function generateTokenFromMagicLink(token) {
  const response = await fetch(
    `${getAPIUrl()}/api/magic/token`,
    {
      body: JSON.stringify({
        token
      }),
      method: 'POST'
    }
  )
  if (response.status !== 200) {
    throw new Error(response.error)
  }
  const data = await response.json()
  return data
}

export async function generateTokenFromOTP(token) {
  const response = await fetch(
    `${getAPIUrl()}/api/otp/token`,
    {
      body: JSON.stringify({
        token
      }),
      method: 'POST'
    }
  )
  if (response.status !== 200) {
    throw new Error(response.error)
  }
  const data = await response.json()
  return data
}

export async function createMagicLink(email) {
  const response = await fetch(
    `${getAPIUrl()}/api/magic/link`,
    {
      body: JSON.stringify({
        email
      }),
      method: 'POST'
    }
  )
  if (response.status !== 200) {
    throw new Error(response.error)
  }
  const data = await response.json()
  return data
}

export async function uploadCoverArtToArtist(releaseId, file) {
  const token = await localForage.getItem('token');
  var data = new FormData()
  data.append('file', file)
  const response = await fetch(
    `${getAPIUrl()}/api/artists/${releaseId}/images`,
    {
      body: data,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
  const result = await response.json()
  return result
}

export async function uploadCoverArtToRelease(releaseId, file) {
  const token = await localForage.getItem('token');
  var data = new FormData()
  data.append('file', file)
  const response = await fetch(
    `${getAPIUrl()}/api/releases/${releaseId}/images`,
    {
      body: data,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
  const result = await response.json()
  return result
}

export async function fetchAudioByRecordingId(recordingId) {
  const token = await localForage.getItem("token");
  const response = await fetch(
    `${getAPIUrl()}/api/recordings/${recordingId}/audio`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed fetch upload info");
  }
  const data = response.json()
  return data
}

export async function uploadAudioToRecording(recordingId, file) {
  const token = await localForage.getItem('token');
  var data = new FormData()
  data.append('file', file)
  const response = await fetch(
    `${getAPIUrl()}/api/recordings/${recordingId}/audio`,
    {
      body: data,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
}

export async function uploadFiles(files, onUpdate) {
  // @ts-ignore
  const token = await localForage.getItem('token');
  var data = new FormData()
  data.append('file', files[0])
  const response = await fetch(
    `${getAPIUrl()}/leads/api/uploads`,
    {
      body: data,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
  return await response.json()
}