import React from 'react';

import { styled, Typography } from '@mui/joy';

export const CardTitle = styled(
  Typography, {
    slot: 'root',
    name: 'CardTitle'
  }
)(({ theme }) => ({
  cssFloat: 'left',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: '600',
  overflow: 'hidden'
}))