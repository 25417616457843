import React from 'react';

import i18n from '@/i18n'

import { useState } from 'react';
import { TextField } from '@/components/InputField';
import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';
import { Container } from '@mui/joy';
import { Button } from '@mui/joy';
import { Check } from '@phosphor-icons/react';
import { inviteUser } from '@/actions/aloader';
import { useLabel } from '@/contexts';
import { useEffect } from 'react';

export function InviteUserPage() {
  return (
    <Container>
      <h1>{i18n.t('invite-user')}</h1>
      <InviteUserForm />
    </Container>
  )
}

export function InviteUserForm() {
  const label = useLabel()
  const [values, setValues] = useState({
    label_id: label?.id,
    email: '',
    user_artists: []
  })

  useEffect(() => {
    updateValues({
      label_id: label?.id
    })
  }, [label])

  const handleSubmit = event => {
    event.preventDefault()
    inviteUser(values).then((result) => {
      
    })

    return false
  }

  const updateValues = data => {
    const newValues = {
      ...values,
      ...data
    }
    setValues(newValues)
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        type="email"
        name="email"
        label={i18n.t('email')}
        placeholder={i18n.t('enter-email')}
        onChange={event => {
          updateValues({ [event.target.name]: event.target.value })
        }}
      />
      <SelectRelatedNodes
        label={i18n.t('tracklist')}
        ownerNodeId={null}
        nodeType="artist"
        ownerNodeType="user"
        addLabel={"add-artists"}
        allLabel={i18n.t('all-artists')}
        ownLabel={i18n.t('user-artists')}
        nodes={values?.user_artists ?? []}
        onChange={(user_artists) => updateValues({ user_artists })}
      />
      <Button startDecorator={<Check />} type="submit" variant="solid" color="primary">{i18n.t('invite-user')}</Button>
    </form>
  )
}