import React from 'react';

import { Box } from '@mui/joy';
import { styled } from '@mui/material';

export const SkeletonProvider = styled(
  Box,
  {
    name: 'SkeletonProvider',
    slot: 'root'
  }
)(({ theme }) => ({
  animation: 'skeleton-animation 1s linear infinite',
  maskSize: '400% 100%',
  '&.skeleton-loading': {
    '.skeletable': {
      background: 'linear-gradient(45deg, rgba(127, 127, 127, .5), rgba(200, 200, 200, 5))',
      backgroundClip: 'text',
      textFillColor: 'transparent',
      height: '100pt'
    }
  }
}))

export const Skeleton = styled(
  Box,
  {
    name: 'Skeleton',
    slot: 'root'
  }
)(({ theme }) => ({
  background: 'linear-gradient(140deg, rgba(127, 127, 127, 0.5), rgba(127, 127, 127, 0.3))',
  width: '30pt',
  height: '20pt'
}))
