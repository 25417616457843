import React from 'react';

import { Box, styled, Typography } from '@mui/joy';

export const FeatureCardHolder = styled(
  Box,
  {
    slot: 'root',
    name: 'FeatureCard'
  }
)(({ theme }) => ({
  display: 'flex',
  borderColor: theme.vars.palette.common.black,
  borderWidth: 2,
  borderStyle: 'solid', 
  textDecoration: 'none', 
  flexDirection: 'column',
  gap: 0,
  alignItems: 'stretch',
  color: theme.vars.palette.common.black,
  justifyContent: 'flex-start',
  '*': {
    margin: 1
  }
}))

export function FeatureCard({
  Icon,
  name,
  description,
  children,
  ...props
}) {
  return (
    <FeatureCardHolder sx={{ p: 1 }} {...props}>
      <Icon weight="bold" />
      <Typography sx={{ fontSize: '16px' }} level="h3" component="h3">{name}</Typography>
      <Typography sx={{ fontSize: '16px', opacity: 0.5 }}>{description}</Typography>
    </FeatureCardHolder>
  )
}