import React, { useState } from 'react' 
import { updateLabel, uploadImageToLabel } from '@/actions/aloader'
import { ColorField, TextField } from '@/components/InputField'
import { VBox } from '@/components/VBox'
import { useResource } from '@/hooks'
import { extractColors } from 'extract-colors'
 
import { Link, useParams } from 'react-router-dom'
import i18n from '@/i18n'
import { Button, Container, Typography } from '@mui/joy'
import { Loading } from '@/components/Loading'
import { ArtworkField } from '@/components/ArtworkField'
import { useEffect } from 'react'
import { Plus } from '@phosphor-icons/react/dist/ssr'
import { Check } from '@phosphor-icons/react'


export default function EditLabelPage() {
  const { labelId } = useParams()
  return ( 
      <EditLabelView labelId={labelId} /> 
  )
}

export function EditLabelView({
  labelId
}) {
  const { data: label, refetch, isLoading } = useResource({
    queryKey: ['label', labelId],
    path: `/labels/${labelId}`
  })
  const [values, setValues] = useState(label)
  const [image, setImage] = useState(null)
  useEffect(() => {
    setValues(label)
  }, [label])

  const loading = true

  useEffect(() => {
    if (values?.imageUrl) {
      extractColors(values.imageUrl, { crossOrigin: true }).then(data => {
        if (values.logotypeAccentColor != data[0].hex) {
          setValues({ ...values, logotypeAccentColor: data[0].hex})
        }
      }).catch(e => {
        console.error(e)
      })
    }
  }, [values?.imageUrl])

  const handleSubmit = event => {
    event.preventDefault()
    updateLabel(values).then(() => {
      if (image) {
        uploadImageToLabel({ labelId, file: image }).then(() => {
          refetch()
        })
      } else {
        refetch()
      }
      // TODO Print confirmation
    })
    return false
  }
  return (
    <Container>
      <form method="POST" onSubmit={handleSubmit}>
        <VBox> 
          <Link to={`/admin/label/${labelId}`}>{i18n.t('back-to-label')}</Link>
          <Typography level="title-lg">{i18n.t('edit-label')}</Typography>
          <ArtworkField
            imageUrl={values?.imageUrl}
            onChange={(file) => setImage(file)}
            label={i18n.t('logotype')}
          />
          <TextField
            type="text"
            label={i18n.t('name-of-label')}
            defaultValue={values?.name}
            loading={loading}
            placeholder={i18n.t('name-of-label')}
            required
            onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
          />
          <ColorField
            label={i18n.t('accent-color')}
            defaultValue={values?.color}
            placeholder={i18n.t('accent-color')}
            name="color"
            required
            onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setValues({ ...values, color: values?.logotypeAccentColor })}
            >
              {i18n.t('pick-from-logotype')}
            </Button>
          </ColorField>
          <Button type="submit" variant="solid" sx={{ alignSelf: 'flex-start' }} color="primary" startDecorator={<Check />}>{i18n.t('save-label')}</Button>
        </VBox>
      </form>
    </Container>
  )
}