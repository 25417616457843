import React, { createContext, useEffect, useState } from 'react';
import { useContext } from 'react';

export const SwitchContext = createContext({
  value: null,
  setValue: () => {}
})

export function SwitchCase({
  value: defaultValue,
  children
}) {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <SwitchContext.Provider value={{ value }}>
      {children} 
    </SwitchContext.Provider>
  )
}

export function Case({
  match,
  children
}) {
  const {
    value 
  } = useContext(SwitchContext)
  console.log("value", value)
  console.log("match", match)
  console.log("children", children)
  if (value === match) {
    return <>{children}</>
  } else {
    return null
  }
}

export const ForContext = createContext({
  collection: null,
  i: 0
})

export function ForEach({
  collection,
  children,
  empty
}) {
  return (
    <ForContext.Provider value={{ collection }}>
      {children}
    </ForContext.Provider>
  )
}

export function For({
  collection,
  children,
  empty
}) {
  return (
    <ForContext value={{ collection, empty }}>
      {children}
    </ForContext>
  )
}

export function Do({
  children
}) {
  const { collection } = useContext(ForContext)
  if (collection?.length > 0) {
    return collection.map((node, i) => children(node, i))
  }
  return null
}

export function Empty({
  children
}) {
  const { collection } = useContext(ForContext)
  if (collection?.length > 0) {
    return null
  }
  return children
}

export function If({
  value,
  renderElse={},
  children
}) {
  if (value) {
    return children
  } else {
    return renderElse
  }
}
