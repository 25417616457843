import { Box, CircularProgress, styled } from '@mui/joy';
import React from 'react';

export const LoadingScreenRoot = styled(
    Box,
    {
        slot: 'root',
        name: 'LoadingScreen'
    }
)(({ theme }) => ({
    zIndex: 5000,
    background: theme.palette.background.body,
    scroll: 'none',
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}))

export function LoadingScreen() {
    return (
        <LoadingScreenRoot>
            <CircularProgress />
        </LoadingScreenRoot>
    )
}