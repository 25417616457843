import React, { useState } from 'react';
import { Box, Button, Container, Grid } from '@mui/joy';

import i18n from '@/i18n'

import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';

import { useHostname } from '@/contexts';
import { Img } from '@/components/Img';
import { HBox } from '@/components/HBox';
import { Link } from 'react-router-dom';
import { AppleLogo, SpotifyLogo, TidalLogo } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { useResource } from '@/hooks';
import { UserView } from '@/components/UserView';
import { Do, Empty, ForEach } from '@/components/Syntax';
import { ReleaseCard } from '@/components/ReleaseCard';

export function PayeeView({ payeeId }) {
  const hostname = useHostname()
  const navigate = useNavigate()

  const [values, setValues] = useState({})

  const { data: payee, loading } = useResource({
    queryKey: ['payee', payeeId],
    path: `/payee/${payeeId}`
  })

  if (loading) {
    return <Loading />
  }

  if (payee) {
    return (
      <Container>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
          <h1>{payee.name}</h1>
          <Button
            component={Link}
            to={`/dashboard/payee/${payee.id}/edit`}
            variant="outlined"
          >{i18n.t('edit')}</Button>
          <Img src={payee.image_url} style={{ marginTop: 1, width: '120pt' }} />
          <h3>{i18n.t('users')}</h3>
          <HBox>
            <ForEach collection={payee.payee_users}>
              <Do>
                {member => (
                  <UserView key={member.id} user={member.user} />
                )}
              </Do>
              <Empty>
                <i>Payee has no users</i>
              </Empty>
            </ForEach>
          </HBox>
        </Box>
      </Container>
    )
  }
}

export default function PayeePage() {
  const { payeeId } = useParams()

  return (
    <React.Suspense fallback={<Loading />}>
      <PayeeView payeeId={payeeId} />
    </React.Suspense>
  )
}