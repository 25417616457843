import React, { useState } from 'react';
import { Box, Button, Container } from '@mui/joy';

import i18n from '@/i18n'

import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';

import { useHostname } from '@/contexts';
import { Img } from '@/components/Img';
import { HBox } from '@/components/HBox';
import { Link } from 'react-router-dom';
import { AppleLogo, SpotifyLogo, TidalLogo } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { useResource } from '@/hooks';
import { UserView } from '@/components/UserView';

export function PersonView({ personId }) {
  const hostname = useHostname()
  const navigate = useNavigate()

  const [values, setValues] = useState({})

  const { data: person, loading } = useResource({
    queryKey: ['person', personId],
    path: `/person/${personId}`
  })

  if (loading) {
    return <Loading />
  }

  if (person) {
    return (
      <Container>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
          <h1>{person.name}</h1>
          <Button
            onClick={() => navigate(`/dashboard/person/${person.id}/edit`)}
            variant="outlined"
          >{i18n.t('edit')}</Button>
          <Img src={person.image_url} style={{ marginTop: 1, width: '120pt' }} />
        </Box>
      </Container>
    )
  }
}

export default function PersonPage() {
  const { personId } = useParams()

  return (
    <React.Suspense fallback={<Loading />}>
      <PersonView personId={personId} />
    </React.Suspense>
  )
}