import React from 'react';

export function Table({
  children,
  ...props
}) {
  return (
    <table className="table" {...props}> 
        {children}
    </table> 
  )
}