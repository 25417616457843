import React from 'react'

import { useResource } from '@/hooks'
import { Box, Container, Typography } from '@mui/joy'

import i18n from '@/i18n'
import { Link } from 'react-router-dom'
import { Books } from '@phosphor-icons/react'
import { FeatureCard } from '@/components/FeatureCard'

export function GuidesPage() {
    const { data: docs } = useResource({
        queryKey: ['docs'],
        path: `/docs`
    })
    return (
        <Container>
            <Typography level="h1" component="h1">{i18n.t('guides')}</Typography>
            <Box sx={{ display: 'grid', gap: 1,  gridTemplateColumns: '1fr 1fr' }}> 
                {docs?.results?.map(doc => (
                    <FeatureCard    
                        as={Link}
                        to={doc.id}
                        Icon={Books}
                        name={doc.name}
                        description={doc.summary}
                    />
                ))}
            </Box>
        </Container>
    )
}