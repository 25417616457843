import React from 'react';
import { Widget } from '@typeform/embed-react'
import '@typeform/embed/build/css/widget.css';

import { useLabel } from '@/contexts'; 
import { ErrorView } from '@/components/ErrorView';
import { LoadingPage } from '@/components/LoadingPage';

export function PitchPage() {
    const label = useLabel()
    return ( 
        <React.Suspense fallback={<LoadingPage />}>
            {label && <TypeFormPitch />}
        </React.Suspense>
    )

}

export function TypeFormPitch() {
  const label = useLabel()
    if (!label) {
        return (
            <ErrorView />
        )
    }
    if (label?.typeformId) {
        return (
            <Widget id={label?.typeformId} style={{ width: '100%', height: '100%' }} />
        )
    } else if (label.pitchFormEmbedUrl) {
        return (
            <iframe src={label.pitchFormEmbedUrl} style={{ width: '100%', height: '100%' }} />
        )
    } else {
        return (
            <ErrorView />
        )
    }
}

export default PitchPage;