import { Route, Navigate, Outlet } from "react-router-dom";

import ArtistPage from '@/pages/dashboard/artist/[artistId]';
import EditArtistPage from '@/pages/dashboard/artist/[artistId]/edit';
import AddArtistPage from '@/pages/dashboard/artist/add';
import RecordingsPage from '@/pages/dashboard/recordings';
import AddRecordingPage from '@/pages/dashboard/recording/add';
import EditRecordingPage from '@/pages/dashboard/recording/[recordingId]/edit';
import PeoplePage from '@/pages/dashboard/people';
import EditPersonPage from '@/pages/dashboard/person/[personId]/edit';
import PersonPage from '@/pages/dashboard/person/[personId]';
import PayeesPage from '@/pages/dashboard/payees';
import EditPayeePage from '@/pages/dashboard/payee/[payeeId]/edit';
import AddPayeePage from '@/pages/dashboard/payee/add';
import PayeePage from '@/pages/dashboard/payee/[payeeId]';
import LabelsPage from '@/pages/dashboard/admin/labels';
import AdminPage from '@/pages/dashboard/admin';
import LabelPage from '@/pages/dashboard/admin/label/[labelId]';
import EditLabelPage from '@/pages/admin/label/[labelId]/edit';
import EditReleasePage from '@/pages/dashboard/release/[releaseId]/edit';
import RecordingPage from '@/pages/dashboard/recording/[recordingId]';
import MarketingPage from '@/pages/dashboard/marketing';
import MerchPage from '@/pages/dashboard/marketing/merch';
import PreSavePage from '@/pages/dashboard/marketing/pre-save';
import StatisticsPage from '@/pages/dashboard/statistics/index.jsx';
import PitchPage from '@/pages/dashboard/marketing/pitch';
import UsersPage from '@/pages/dashboard/users';
import { InviteUserPage } from '@/pages/dashboard/user/add';
import PromotionPage from '@/pages/dashboard/marketing/promotion';
import DiscoveryModePage from '@/pages/dashboard/marketing/discovery-mode';
import DashboardLayout from '@/pages/dashboard/layout';
import ReleasesPage from '@/pages/dashboard/releases';
import DashboardPage from '@/pages/dashboard';
import ArtistsPage from '@/pages/dashboard/artists';
import AddReleasePage from '@/pages/dashboard/release/add';
import ExternalPage from '@/pages/dashboard/docs/[slug]'

import accountsRoutes from '@/pages/accounts/routes'
import { GuidesPage } from "./docs";

export default (
    <>
        {accountsRoutes}
        <Route path="dashboard" element={<DashboardLayout />}>
            <Route index element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<DashboardPage />} />
            <Route path="docs" element={<Outlet />}>
                <Route index element={<GuidesPage />} />
                <Route path=":slug" element={<Outlet />}>
                    <Route index element={<ExternalPage />} />
                </Route>
            </Route>
            <Route path="guides" element={<Outlet />}>
                <Route index element={<GuidesPage />} />
                <Route path=":slug" element={<Outlet />}>
                    <Route index element={<ExternalPage />} />
                </Route>
            </Route>
            <Route path="artist" element={<Outlet />}>
                <Route path=":artistId" element={<Outlet />}>
                <Route index element={<ArtistPage />} />
                <Route path="edit" element={<EditArtistPage />} />
                </Route>
                <Route path="add" element={<AddArtistPage />} />
            </Route>
            <Route path="releases" element={<ReleasesPage />} />
            <Route path="recordings" element={<RecordingsPage />} />
            <Route path="admin" element={<Outlet />}>
                <Route index element={<Navigate to="users" />} replace />
                <Route path="labels" element={<LabelsPage />} />
                <Route path="label" element={<Outlet />}>
                <Route path=":labelId" element={<Outlet />}>
                    <Route index element={<LabelPage />} />
                    <Route path="edit" element={<EditLabelPage />} />
                </Route>
                <Route path="add" element={<AddRecordingPage />} />
                </Route>
                <Route path="people" element={<PeoplePage />} />
                <Route path="payees" element={<PayeesPage />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="payee" element={<Outlet />}>
                <Route path=":payeeId" element={<Outlet />}>
                    <Route index element={<PayeePage />} />
                    <Route path="edit" element={<EditPayeePage />} />
                </Route>
                <Route path="add" element={<AddPayeePage />} />
                </Route>
                <Route path="user" element={<Outlet />}>
                <Route path="add" element={<InviteUserPage />} />
                </Route>
            </Route>
            <Route path="recording" element={<Outlet />}>
                <Route path=":recordingId" element={<Outlet />}>
                <Route index element={<RecordingPage />} />
                    <Route path="edit" element={<EditRecordingPage />} />
                </Route>
                <Route path="add" element={<AddRecordingPage />} />
            </Route>
            <Route path="track" element={<Outlet />}>
                <Route path=":recordingId" element={<Outlet />}>
                <Route index element={<RecordingPage />} />
                    <Route path="edit" element={<EditRecordingPage />} />
                </Route>
                <Route path="add" element={<AddRecordingPage />} />
            </Route>
            <Route path="release" element={<Outlet />}>
                <Route path=":releaseId" element={<Outlet />}>
                <Route index element={<Navigate to="edit" replace />} />
                <Route path="edit" element={<EditReleasePage />} />
                </Route>
                <Route path="add" element={<AddReleasePage />} />
            </Route>
            <Route path="artists" element={<ArtistsPage />} />
            <Route path="person" element={<Outlet />}>
                <Route path=":personId" element={<Outlet />}>
                <Route index element={<PersonPage />} />
                <Route path="edit" element={<EditPersonPage />} />
                </Route>
                <Route path="add" element={<AddRecordingPage />} />
            </Route>
            <Route path="marketing" element={<Outlet />}>
                <Route index element={<MarketingPage />} />
                <Route path="merch" element={<MerchPage />} />
                <Route path="promotion" element={<PromotionPage />} />
                <Route path="pre-save" element={<PreSavePage />} />
                <Route path="pitch" element={<PitchPage />} />
                <Route path="discovery-mode" element={<DiscoveryModePage />} />
            </Route>
            <Route path="statistics" element={<StatisticsPage />} />
        </Route>
    </>
)
