import React from 'react'

import { Typography, styled } from '@mui/joy'

export const CardMetaData = styled(
  Typography,
  {
    slot: 'root',
    name: 'CardMetaData'
  }
)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'capitalize'
}))