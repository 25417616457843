import React from 'react';

import { PulseLoader } from 'react-spinners';

export function LoadingPage({
  loadingText
}) {
  return (
    <div className="central"><PulseLoader /><span>{loadingText}</span></div>
  )
}