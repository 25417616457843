import React from 'react';

import { Input as BaseInput } from '@mui/base/Input';

import { Box, styled, Skeleton, Typography } from '@mui/joy';
import { HexColorPicker } from "react-colorful";
import { useState } from 'react';
import { useEffect } from 'react';
import { ExclamationMark, Info } from '@phosphor-icons/react';

const InputFieldLabel = styled(Box, {
  name: 'InputField', // The component name
  slot: 'label', // The slot name
})(({ theme }) => ({
  backgroundColor: theme.vars.palette.background.surface,
  borderWidth: theme.variants.outlined.borderWidth,
  borderStyle: 'solid',
  boxShadow: theme.vars.shadow.md,
  alignSelf: 'flex-start',
  fontSize: '16px',
  padding: '8px 12px',
  borderBottomWidth: 0,
  borderColor: theme.variants.outlined.neutral.borderColor,
  fontWeight: 600,
  transform: 'translateY(1px)'
}));

export const InputFieldFrame = styled('div', {
  name: 'InputField',
  slot: 'frame'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  gap: 1,
  justifyContent: 'stretch',
  borderRadius: 0,
  padding: 0,
  boxShadow: 'none',
  maxHeight: 200,
  borderStyle: 'solid',
  borderColor: theme.variants.outlined.neutral.borderColor,
  backgroundColor: theme.vars.palette.background.surface,
  borderWidth: theme.variants.outlined.borderWidth,
  
  /*button: {
    color: theme.vars.palette.primary.solidBg + ' !important'
  }*/
}));

export const InputFieldRoot = styled(Box, {
  name: 'InputField',
  slot: 'root'
})(({ theme }) => ({
  '&:focus-within': {
    div: {
      borderColor: 'CanvasText'
    },
    
  },
  /*'&:has(:invalid)': {
    color: theme.vars.palette.danger[500],
    div: {
      borderColor: theme.vars.palette.danger[500]
    }
  },*/
  '&.error': {
    color: theme.vars.palette.danger[500],
    div: {
      borderColor: theme.vars.palette.danger[500]
    }
  },
}))

const InputFieldText = styled(
  "input", 
  {
    name: 'InputField',
    slot: 'value',
    shouldForwardProp: (props) => props !== "focusColor"
  }
)(({ theme }) => ({
  border: 'none',
  flex: 1,
  padding: '8px 12px',
  fontSize: '16px',
  fontFamily: "DM Mono",
  fontWeight: 'light',
  '&::placeholder': {
    fontFamily: "DM Mono",
  },
  '&:focus': {
    outline: '2pt solid CanvasText'
  }
}))

export function InputField({
  label,
  element,
  loading = false,
  info,
  errors=[],
  children
}) {
  return (
    <InputFieldRoot className={errors?.length > 0 ? 'error' : ''} sx={{ display: 'flex', marginBottom: 1, flexDirection: 'column', alignItems: 'stretch' }}>
      <InputFieldLabel>{label}</InputFieldLabel>
      <Box sx={{ transform: 'translateY(-1pt)', position: 'relative', minHeight: '30pt'}}>
        {loading ?
          <Skeleton loading={loading} />: <>{element}{children}</>
        }
      </Box>
      {errors?.map(error => (
        <Typography startDecorator={<ExclamationMark />} color="danger">{error.description}</Typography>
      ))}
      {info &&
        <Typography sx={{ opacity: 0.5 }}>{info}</Typography>
      }
    </InputFieldRoot>
  )
}

export function TextField({
  label,
  loading = false,
  errors,
  info,
  ...props
}) {
  return (
    <InputField
      label={label}
      info={info}
      errors={errors}
    >
      <InputFieldFrame>
        <InputFieldText {...props} />
      </InputFieldFrame>  
    </InputField>
  )
}

export function ColorField({
  label,
  defaultValue,
  onChange,
  name,
  info,
  children,
  ...props
}) {
  const [color, setColor] = useState(defaultValue)
  useEffect(() => {
    setColor(defaultValue)
  }, [defaultValue])
  const handleSetColor = (value) => {
    setColor(value)
    onChange?.({
      target: {
        name,
        value
      }
    })
  }
  return (
    <InputField
      label={label}
      info={info}
      {...props}
    >
      <InputFieldFrame sx={{ border: 'none', gap: 1, flexDirection: 'row', alignItems: 'flex-start' }}>
        <HexColorPicker color={color} style={{ borderRadius: 0 }} onChange={handleSetColor} />
        {children}
      </InputFieldFrame>
    </InputField>
  )
}