import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { logout } from '@/actions/login';

export function LogoutPage() {
    const navigate = useNavigate()
    useEffect(() => {
        logout().then(() => {
           navigate('/')
        }) 
       }, [])
    return (
        <>Logging out</>
    )
}

export default LogoutPage;