import { useTheme } from '@mui/joy';
import moment from 'moment'

export const EventFlyOut = ({ x, y, height, datum, dx, dy }) => (
  <>
    <defs>
      <filter id="shadow">
        <feDropShadow dx="0" dy="0" floodOpacity={0.5}  />
      </filter>
    </defs>
    <g>
      {/*<line
        x1={x}
        y1={y}
        x2={x}
        y2={(y * 2.5)}
        stroke="black"
      />*/}
      <circle
        cx={x}
        cy={y}
        r={10}
        stroke-width={2}
        style={{ fill: 'var(--primary)', stroke: 'white', filter: 'url(#shadow)' }}
      />
      <rect
        x={x}
        y={y}
        width="200"
        dx={dx}
        dy={dy}
        height="100"
        rx="4.5"
        style={{ fill: datum?.object?.colorScheme?.primary?.dark ?? 'white', filter: 'url(#shadow)' }}
      />
      <image
        x={x + 0}
        y={y + 0}
        width={50}
        height={50}
        href={datum?.object?.imageUrl}
        fill="currentColor"
        style={{ opacity: 1 }}
      />
      <text
        x={x + 60}
        y={y + 20}
        font-size="20"
        font-weight="bold"
        fill={datum?.object?.colorScheme?.primary?.light ?? "currentColor"}
        style={{ opacity: 1 }}
      >
        {datum?.object.name}
      </text> 
      <text
        x={x + 60}
        y={y + 40}
        font-size="10"
        fill={datum?.object?.colorScheme?.primary?.light ?? "currentColor"}
      >
        {`${datum.action.name} · ${moment(datum.date).format('LL')}`}
      </text> 
    </g>
  </>  
);

export const FlyOut = ({ x, y, height, datum, dx, dy }) => {
  const theme = useTheme()
  const color = theme.palette.primary.solidBg
  return (
    <svg height="79.5%">
      <defs>
        <filter id="shadow" y="-60%" x="-60%" width="200%" height="200%">
          <feDropShadow dx="0" dy="0" floodOpacity={0.5}  />
        </filter>
        <filter id="shadow2" y="-200%" x="-200%" width="500%" height="500%">
          <feDropShadow dx="0" dy="1" floodOpacity={0.2}  />
        </filter>
      </defs>
      <g>
        {<line
          x1={x}
          y1={y}
          x2={x}
          y2={"100%"}
          stroke-width={"0.5px"}
          stroke={"black"}
        />}
        <circle
          cx={x}
          cy={y}
          r={5} 
          stroke-width={2}
          style={{ fill: color, stroke: 'white', filter: 'url(#shadow)' }}
        />
        <svg
          x={x + 10}
          y={y - 10}
          dx={dx}
          dy={dy}
        >
          <rect
            width="70"
            height="28"
            rx="4.5"
            style={{ transform: 'translate(5px, 5px)', fill: 'white', filter: 'url(#shadow2)' }}
          />
          <text
            x={12}
            y={16}
            font-size="6"
            fill="#868C97"
          >
            {`${moment(datum.date).format('LL')}`}
          </text>
          <text
            x={12}
            y={26}
            font-size="6"
            font-weight="bold"
            fill="#868C97"
          >
            {`${(datum.streams ?? 0)?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })} streams`}
          </text> 
        </svg>
      </g>
    </svg>  
  );
}
