import React from 'react'

import { Container } from '@mui/joy'

import i18n from '@/i18n'

export default function PreSavePage() {
  return (
    <Container>
      <h3>{i18n.t('pre-save')}</h3>
    </Container>
  )
}