import React from 'react'; 
import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';

import { db, collection, addDoc } from '@/client';
import { useNavigate } from 'react-router-dom';
import { RecordingForm } from './[recordingId]/edit';
import { uploadAudioToRecording } from '@/actions/login';
import { enqueueSnackbar } from 'notistack';

import i18n from '@/i18n'
import { Container } from '@mui/joy';
import { FeatureCard } from '@/components/FeatureCard';
import { Link } from 'react-router-dom';
import { CardsThree, Waveform } from '@phosphor-icons/react';

export default function AddRecordingPage() {
  const { recordingId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <AddRecordingView recordingId={recordingId} />
    </React.Suspense>
  )
}

export function AddRecordingView() {
  const navigate = useNavigate()
  const handleSubmit = async (values) => {
    try {
      const recording = await addDoc(collection(db, 'recordings'), values)
      if (values.audio) {
        await uploadAudioToRecording(recording.id, values.audio)
        enqueueSnackbar(i18n.t('track-saved'))
      } else {
      }
      enqueueSnackbar(i18n.t('track-saved'))
      navigate(`/dashboard/recording/${recording.id}`)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Container>
      <FeatureCard
        sx={{ marginTop: 2, p: 1 }}
        as={Link}
        to="../../release/add"
        Icon={CardsThree}
        name={i18n.t('start-create-track.heading')}
        description={i18n.t('start-create-track.description')}
      /> 
      <h1>{i18n.t('add-track')}</h1>
      <RecordingForm
        method="POST"
        onSubmit={handleSubmit}
        submitLabel={i18n.t('save-track')}
      />
    </Container>
  )
}