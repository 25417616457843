
import React, { useState, useRef } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/joy';

const ImagePlaceholder = styled('div', {
  name: 'AHolder', // The component name
  slot: 'root', // The slot name
})(({ theme }) => ({ 
  backgroundColor: theme.vars.palette.background.surface,
  borderRadius: 0,
  boxShadow: 'none',
  borderWidth: theme.variants.outlined.borderWidth,
  borderColor: theme.variants.outlined.neutral.borderColor,
  borderStyle: 'solid',
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  aspectRatio: '1/1',
  padding: 0,
  fontWeight: 800, 
}));
 
const ACrossH = styled('div', {
  name: 'ACrossH', // The component name
  slot: 'root', // The slot name
})(({ theme }) => ({ 
  width: '150%',
  position: 'absolute',
  height: '1px',
  background: 'CanvasText',
  borderColor: theme.variants.outlined.neutral.borderColor,
  transform: 'rotate(135deg)'
}));
 
const ACrossV = styled('div', {
  name: 'ACrossH', // The component name
  slot: 'root', // The slot name
})(({ theme }) => ({ 
  width: '1px',
  position: 'absolute',
  height: '150%',
  background: 'CanvasText',
  borderColor: theme.variants.outlined.neutral.borderColor,
  transform: 'rotate(-45deg)'
}));
export function Img({
  PlaceholderIcon = null,
  src,
  ...props
}) {
  const [loaded, setLoaded] = useState(false)
  const imageRef = useRef(null)
  let placeholder = (
    <ImagePlaceholder sx={{ ...props.style }}>
      <ACrossH />
      <ACrossV />
    </ImagePlaceholder>
  )
  if (PlaceholderIcon) {
    placeholder = (
      <Box sx={{ background: 'rgba(127, 127, 127, .1)', display: 'flex', alignItems: 'center', justifyContent: 'center', ...props.sx }}>
        <PlaceholderIcon size={50} />
      </Box>
    )
  }

  if (!src) {
    return placeholder
  }

  return (
    <img ref={imageRef} src={src} style={props.sx} {...props} />
  )
}