import { createContext, useContext } from 'react';

export const SessionContext = createContext({
  session: null,
  setSession: () => { },
  user: null,
  setUser: () => {},
  label: null,
  setLabel: () => {},
  hostname: 'localhost:3000',
  setHostname: () => {},
  role: null,
  setRole: (role) => {}
})

export function useLoadSession() {
  const { loadSession } = useContext(SessionContext)
  return loadSession
}

export function useSessionContext() {
  return useContext(SessionContext)
}

export function useLabel() {
  const { label } = useSessionContext()
  return label
}

export function useUser() {
  const { user } = useSessionContext()
  return user
}

export function useHostname() {
  const { hostname } = useSessionContext()
  return hostname
}

export function useSession() {
  const { session } = useSessionContext()
  return session
}

export function useRole() {
  const { role } = useSessionContext()
  return role
}
