import React, { useState } from 'react'

import { Box, Button } from '@mui/joy'
import i18n from '@/i18n'
import { Check, X } from '@phosphor-icons/react'

import { TextField } from './InputField' 

import { addDoc, db, collection } from '@/client'
import { HBox } from './HBox'
import { useEffect } from 'react'

export function CreateNodeForm({
  nodeType,
  defaultName='',
  onNodeCreated,
  onClose,
  fields
}) {
  const [values, setValues] = useState({
    name: defaultName
  })
  const [error, setError] = useState(false)
  const [status, setStatus] = useState('ready')

  useEffect(() => {
    setValues({
      name: defaultName
    })
  }, [defaultName])
  const handleSubmit = event => {
    event.preventDefault()
    setStatus('loading')
    addDoc(
      collection(
        db,
        nodeType
      ),
      values
    ).then(data => {
      onNodeCreated(data)
      onClose()
      setStatus('created')
    }).catch(e => {
      console.error(e)
      setStatus('error')
      setError(e)
    })
    return false
  }
  const updateValues = (inputValues) => {
    const newValues = {
      ...values,
      ...inputValues
    }
    setValues(newValues)
  }
  return (
    <>
      <Box sx={{ flexDirection: 'column', display: 'flex'}}>
        {fields.map(field => {
          switch(field.type) {
          default:
            return (
              <TextField
                key={field.id}
                type={field.type}
                label={field.label}
                defaultValue={values[field.id]}
                name={field.id}
                onChange={(event) => updateValues({ [field.id]: event.target.value })}
                required={field.required}
                {...field.props ?? {}}
              />
            )
          }
        })}
      </Box>
      <HBox>
        <Button
          startDecorator={<Check />}
          onClick={handleSubmit}
        >
          {i18n.t('add-node', { nodeType })}
        </Button>
        <Box sx={{ flex: 1 }} />
        <Button
          variant="outlined"
          startDecorator={<X />}
          onClick={onClose}
          sx={{ justifySelf: 'flex-start', alignSelf: 'flex-start' }}
        >{i18n.t('cancel')}</Button>
      </HBox>
    </>
  )
}