import React from 'react'

import { Box, Container, Grid } from '@mui/joy'
import { Binoculars, Broadcast, MusicNotesPlus, Radio, ShirtFolded, TShirt, Users, UsersThree } from '@phosphor-icons/react'
import { FeatureCard } from '@/components/FeatureCard'

import i18n from '@/i18n'
import { Link } from 'react-router-dom'

export default function MarketingPage() {
  return (
    <Container>
      <h3>{i18n.t('marketing')}</h3>
      <Box sx={{ display: 'grid', gap: 1,  gridTemplateColumns: '1fr 1fr' }}> 
        <FeatureCard
          as={Link}
          to={`pitch`}
          Icon={Broadcast}
          name={i18n.t('pitch-release')}
          description={i18n.t('pitch-your-release-to-editorial-teams-at-streaming-service')}
        />
        {/*<FeatureCard
          as={Link}
          to="pre-save"
          Icon={MusicNotesPlus}
          name={i18n.t('pre-save')}
          description={i18n.t('create-a-landing-page')}
        /> 
        <FeatureCard
          as={Link}
          Icon={TShirt}
          to="merch"
          name={i18n.t('merch')}
          description={i18n.t('create-and-sell-your-merch')}
        /> 
        <FeatureCard
          as={Link}
          to="promotion"
          Icon={UsersThree}
          name={i18n.t('artist-promotion')}
          description={i18n.t('get-a-quote-for-pr')}
        /> 
        <FeatureCard
          as={Link}
          to="discovery-mode"
          Icon={Binoculars}
          name={i18n.t('discovery-mode')}
          description={<>...</>}
        />*/}
      </Box>
    </Container>
  )
}