import { createContext, useContext } from 'react';

export const PlayerContext = createContext({
    recording: null,
    pause: () => {},
    resume: () => {},
    status: 'stopped',
    setRecording: () => {}
})

export function usePlayer() {
    return useContext(PlayerContext)
}
