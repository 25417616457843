import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import sv from './lang/sv.json';
import en from './lang/en.json';

import LanguageDetector from 'i18next-browser-languagedetector';

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import svSe from 'date-fns/locale/sv';

registerLocale('sv', svSe)
setDefaultLocale('sv')


i18next
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      'en': {
        translation: en
      },
      /*'sv-SE': {
        translation: sv
      }*/
    },
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18next
