import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { createFragmentRegistry } from '@apollo/client/cache';

import { setContext } from '@apollo/client/link/context';
import localForage from 'localforage';
import { getAPIUrl } from '@/utils'
import axios from 'axios'

import fragments from './fragments';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';

const initCache = (initialState) => {
  const cache = new InMemoryCache({
    fragments: createFragmentRegistry(
      fragments
    )
  }).restore(initialState || {});
  
  /**
   * Cache uses localStorage to save data.
   * 
   * This cache is used by Apollo (graphql client).
   */
  if(typeof window !== 'undefined') {
    persistCache({
      cache,
      storage: window.localStorage
    });
  }
  
  return cache;
}

const cache = new InMemoryCache({
  typePolicies: {
    keyFields: false
  }
})

export const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

export const httpLink = createHttpLink({
  uri: `${getAPIUrl()}/graphql/`,
});

export const authorizedFetch = async (method, path, params, defaultQuery) => {
  let query = {}
  for (let k in defaultQuery) {
    if (defaultQuery[k]) {
      query[k] = defaultQuery[k]
    }
  }
  const res = await fetch(
    `${getAPIUrl()}/api/v2${path}?${new URLSearchParams(query).toString()}`, {
    ...params,
    method,
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await localForage.getItem('token')}`,
      ...params?.headers
    }
  })

  if (res.status < 200 && res.status > 299) {
    throw new Error(res.status)
  }
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(params?.method)) {
    await queryClient.invalidateQueries()
  }

  return await res.json()
}

export const authorizedCoreFetch = async (method, path, params, defaultQuery) => {
  
  let query = {}

  for (let k in defaultQuery) {
    if (typeof defaultQuery[k] !== 'undefined') {
      query[k] = defaultQuery[k]
    }
  }

  const res = await fetch(
    `${getAPIUrl()}/api/v2${path}?${new URLSearchParams(query).toString()}`, {
    ...params,
    method,
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await localForage.getItem('token')}`,
      ...params?.headers
    }
  })

  if (res.status < 200 && res.status > 299) {
    throw new Error(res.status)
  }
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(params?.method)) {
    await queryClient.invalidateQueries()
  }

  return res
}

export const authorizedFetchResults = async (method, path, params, query) => {
  const result = await authorizedFetch(method, path, params, query)
  return result
} 

class Resource {
  constructor(client, path, id) {
    this.client = client
    if (path instanceof Array) {
      this.collectionName = path.join('/')
    } else {
      this.collectionName = path
    }
    this.id = id
  }
  get path() {
    return `/${this.collectionName}${this.id ? `/${this.id}` : ``}`;
  }
  get() {
    return this.client.request('GET', this.path)
  }
  set(data) {
    return this.client.request('PATCH', this.path, { body: JSON.stringify(data)})
  }
  async post(data) {
    return await this.client.request('post', this.path, { body: JSON.stringify(data)})
  }
  put(data) {
    return this.client.request('PUT', this.path, { body: JSON.stringify(data)})
  }
  patch(data) {
    return this.client.request('PATCH', this.path, { body: JSON.stringify(data)})
  }
  delete(data) {
    return this.client.request('DELETE', this.path, { body: JSON.stringify(data)})
  }
}

export function doc(client, ...path) {
  return new Resource(client, ...path)
}

export function collection(client, ...path) {
  return new Resource(client, ...path)
}

export async function addDoc(collection, data) {
  return await collection.post(data)
}

export async function updateDoc(collection, data) {
  return await collection.patch(data)
}

export async function setDoc(collection, data) {
  return await collection.put(data)
}

class Collection {
  constructor(client, collectionName) {
    this.client = client
    this.collectionName = collectionName
  }
  doc(id) {
    return new Resource(
      this.client,
      this.collectionName,
      id
    )
  }
}

class RESTClient {
  async request(method, path, params) {
    const res = await fetch(`${getAPIUrl()}/api/v2${path}`, {

      method,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${await localForage.getItem('token')}`,
        ...params?.headers
      },
      ...params,
    })
    const result = await res.json()
    return result
  }
  collection(collectionName) {
    return new Collection(
      this,
      collectionName
    )
  }
}

export const db = new RESTClient(collection)


export const restFetcher =  async (path) => {
  const token = await localForage.getItem('token');
  const url = `${getAPIUrl()}/api${path}`;
  return axios.get(url, {
    withCredentials: true,
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    }
  })
}
 
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await localForage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    }
  }
});

export const client = new ApolloClient({
  defaultOptions: {
    fetchPolicy: 'no-cache',
    //nextFetchPolicy: 'cache-first',
    //errorPolicy: 'all'
    query: {
    }
  },
  link: link.concat(authLink.concat(httpLink)),
  cache
});

export default client
