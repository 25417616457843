import React from 'react';

import moment from 'moment';

import { Card, Chip, Box, CardContent, Alert, Typography, styled, Button } from '@mui/joy';

import i18n from '@/i18n';
import { Img } from './Img';
import { Broadcast, Disc, Radio } from '@phosphor-icons/react';
import { CardMetaData, CardTitle } from './Card';

export function ReleaseCard({
  name,
  thumbnail_url,
  image_url,
  hasPreRelease,
  released,
  reject_message,
  catalog_number,
  releaseType,
  submitted,
  state,
  ...props
}) {
  const delivered = state == "DELIVERED"
  const isReleased = moment(released).isValid() && moment().isSameOrAfter(released) && submitted
  const timeToPitch = moment(released).isValid() && moment().isBefore(released) && submitted
  const isRejected = state == "REJECTED"
  return (
    <Card sx={{ padding: 0, position: 'relative' }} {...props}>
      {timeToPitch && false && (
        <Alert color="primary" sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', position: 'absolute', left: 0, top: 0, width: '100%' }}>
          <Typography level="h6" component="h6">{i18n.t('pitch-alert.heading')}</Typography>
          <Typography level="body-xs" sx={{ fontSize: 10 }}>{i18n.t('pitch-alert.text')}</Typography>
          <Button sx={{ fontSize: 10 }} color="body" onClick={() => navigate(`/dashboard/marketing/pitch`)} startDecorator={<Broadcast weight="bold" size={18} />}>{i18n.t('create-pitch')}</Button>
        </Alert>
      )}
      <Img PlaceholderIcon={Disc} src={thumbnail_url || image_url} sx={{ aspectRatio: '1/1', width: '100%'}} />
      <CardContent sx={{ gap: '8px', p: 1, paddingTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'uppercase' }}>{catalog_number?.length > 0 ? catalog_number : '-'}</Typography>
        <CardTitle>{name}</CardTitle>
        <Box sx={{ opacity: 0.5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <CardMetaData>{releaseType ?? 'release'}</CardMetaData>
          <CardMetaData>{moment(released).isValid() ? moment(released).format('YYYY-MM-DD') : ''}</CardMetaData>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, minHeight: 30, alignItems: 'center', justifyContent: 'stretch' }}>
          {hasPreRelease && !isReleased && <Chip color="primary">{i18n.t('pre-release')}</Chip>}
          {submitted && !isReleased && !isRejected && <Chip color="primary">{i18n.t('pending')}</Chip>}
          {delivered && !isReleased && <Chip color="primary">{i18n.t('delivered')}</Chip>}
          {isReleased && <Chip color="primary">{i18n.t('released')}</Chip>}
          {isRejected && <Chip color="danger">{i18n.t('rejected')}</Chip>}
        </Box> 
      </CardContent>
    </Card>
  )
}