import React from 'react';
import { Box, Button, Container } from '@mui/joy';
import { TextField } from '@/components/InputField';

import i18n from '@/i18n';
import { useState } from 'react';
import { ArtworkField } from '@/components/ArtworkField';

import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';
import { useHostname } from '@/contexts';
import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';
import { Check, Waveform } from '@phosphor-icons/react';
import { db, collection, addDoc } from '@/client';
import { useNavigate } from 'react-router-dom';
import { ReleaseForm } from './[releaseId]/edit';
import { uploadCoverArtToRelease } from '@/actions/login';
import { FeatureCard } from '@/components/FeatureCard';
import { Link } from 'react-router-dom';

export default function AddReleasePage() {
  const { releaseId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <AddReleaseView releaseId={releaseId} />
    </React.Suspense>
  )
}

export function AddReleaseView() {
  const navigate = useNavigate()

  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async (values) => {
    try {
      setSaving(true)
      const image = values.image
      delete values["image_url"]
      const release = await addDoc(collection(db, 'releases'), values)
      if (image) {
        await uploadCoverArtToRelease(release.id, image)
      }
      navigate(`/dashboard/release/${release.id}`)
      setSaving(false)
    } catch (e) {
      setSaving(false)
      setError(e)
      console.error(e)
    }
  }

  return (
    <Container>
      <FeatureCard
        sx={{ marginTop: 2, p: 1 }}
        as={Link}
        to="../../track/add"
        Icon={Waveform}
        name={i18n.t('create-track-release.heading')}
        description={i18n.t('create-track-release.description')}
      /> 
      <h1>{i18n.t('add-release')}</h1>
      <ReleaseForm
        saving={saving}
        submitLabel={i18n.t('add-release')}
        onSubmit={handleSubmit}
      />
    </Container>
  )
}